/*
|--------------------------------------------------------------------------
| Imports
|--------------------------------------------------------------------------
*/
@import "framework/foundation";
@import "./vendor/bower_components/motion-ui/src/motion-ui";
@include foundation-everything;
@import "framework/settings";
@import "./vendor/bower_components/font-awesome/scss/font-awesome.scss";
@import "./vendor/bower_components/foundation-datepicker/css/foundation-datepicker.scss";
@import "modaal";
/*
|--------------------------------------------------------------------------
| Colors
|--------------------------------------------------------------------------
*/
$green: #27a11a;
@import "components/mixins";
@import "components/fonts";
/*
|--------------------------------------------------------------------------
| General Styles
|--------------------------------------------------------------------------
*/
html {
	font-size: 16px;
}
body {
	font-family: $open;
	font-weight: 300;
	font-size: 16px;
	z-index: 1;
}
.page-break  { display: none; }
.no-js #Top-Left {
  display: none;
}
h1, h2, h3, h4 {
	font-family: $open;
	font-weight: 300;
}
h2.section-head {
	background: white;
	color: $dark-gray;
	margin-bottom: 0;
}
h3.gray-header {
	color: $dark-gray;
	font-size: 1.2rem;
	font-weight: 600;
	border-bottom: 1px solid $dark-gray;
	text-align: center;
	width: 80%;
	margin: 0.5rem auto;
	padding-bottom: 0.25rem;
	box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
h3.page-header {
	color: $primary-color;
	text-align: center;
	border-bottom: 1px solid lighten($primary-color, 10%);
	box-shadow: 0 1px 0px rgba(0, 0, 0, 0.1);
	text-transform: capitalize;
	padding: 10px 0;
	margin-bottom: 2rem;
	font-size: 2rem;
	line-height: 1;
	@include breakpoint (small only) {
		@include font-size(20px);
	}
	small {
		font-size: 50%;
		text-transform: none;
	}
}
h4 {
	font-size: 1.1rem;
}
strong {
	font-weight: 600;
}
a {
	color: $green;
	transition: all 0.5s;
	&:hover,
	&:focus {
		color: darken($green, 20%);
		outline: none;
	}
}
@import "components/buttons";
hr.separator {
	width: 100%;
	display: block;
	height: 1px;
	border: none;
	margin: 1rem auto;
	clear: both;
	box-shadow: none;
}
hr.totalSeparator {
	width: 100%;
	display: block;
	height: 1px;
	border: none;
	border-top: 1px solid $primary-color;
	margin: -5px auto 5px auto;
	clear: both;
	box-shadow: none;
}
*:focus {
    outline: none;
}
@import "components/header";
@import "components/forms";
@import "components/tables";
@import "elements/login";
@import "components/sections";
@import "components/utilities";
@import "elements/footer";
