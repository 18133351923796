/*
|--------------------------------------------------------------------------
| Forms
|--------------------------------------------------------------------------
*/
form.widhStyle {
	hr {
		margin-bottom: 0;
	}
	fieldset {
		margin-top: 1rem;
		border: 1px solid $primary-color;
		padding: 1rem 2rem;
		box-shadow: none;
	}
	label {
		@include font-size(12px);
	}
	input,
	select,
	textarea {
		border-left-color: $primary-color;
		border-bottom-color: $primary-color;
		border-top-color: lighten($primary-color, 35%);
		border-right-color: lighten($primary-color, 35%);
		color: $black;
		height: auto;
		padding: 6px;
		font-size: 14px;
		box-shadow: none;
		border-radius: 3px;
	}
	input.error {
		border-color: red;
	}
	select {
		background-position: right 0rem center;
		background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%2833, 153, 232%29'></polygon></svg>");
	}
	figure {
		img {
			max-width: 80px;
		}
	}
}
