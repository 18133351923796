/*
|--------------------------------------------------------------------------
| Sections
|--------------------------------------------------------------------------
*/
.callout.blue-call {
	border-color: $primary-color;
	padding: 0;
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
	border-radius: 3px;
	.wpd {
		padding: 1rem;
	}
	h4 {
		text-transform: capitalize;
	}
}
.callout.blue-call.wpd {
	padding: 1rem;
	overflow: hidden;
	img {
		max-width: 200px;
	}
}
.section-head {
	background: $dark-gray;
	text-align: center;
	font-size: 1rem;
	color: white;
	padding: 5px 0;
	border-bottom: 2px solid lighten($dark-gray, 10%);
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}
.section-head-blue {
	background: $primary-color;
	border-bottom: 2px solid darken($primary-color, 25%);
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
	text-align: center;
	color: white;
	padding: 5px 0;
}
