/*
|--------------------------------------------------------------------------
| Mixins & Functions
|--------------------------------------------------------------------------
*/
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

::-moz-selection {
  background-color: $green;
  color: white;
}

::-moz-selection {
  background-color: $green;
  color: white;
}
::selection {
  background-color: $green;
  color: white;
}
