a.button {
	margin-bottom: 0;
}
a.radius,
button.radius {
	border-radius: 3px;
}
button {
	transition: all 0.5s;
	margin-bottom: 0;
	&:focus {
		outline: none;
	}
}
button.green {
	border-color: $green;
	color: $green;
	transition: all 0.5s;
	background: white;
	&:hover,
	&:active,
	&:focus {
		border-color: darken($green, 20%);
		color: white;
		box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
		background: $green;
		outline: none;
	}
}
