/*
|--------------------------------------------------------------------------
| Utilities
|--------------------------------------------------------------------------
*/
.u__nmb {
	margin-bottom: 0 !important;
}
.u__greencolor {
	color: $green;
}
.u__yellowcolor {
	color: $warning-color;
}
.u__redcolor {
	color: $alert-color;
}
.u__bluecolor {
	color: $primary-color;
}
