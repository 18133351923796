/*
|--------------------------------------------------------------------------
| Tables
|--------------------------------------------------------------------------
*/
table {
	width: 100%;
	@include breakpoint (small only) {
		@include table-stack();
	}
	thead {
		tr {
			th {
				padding: 5px 0;
				@include font-size(14px);
				font-weight: normal;
				&.tocenter {
					text-align: center;
				}
			}
			&.centered {
				th {
					text-align: center;
				}
			}
		}
	}
	tbody {
		tr {
			td {
				padding: 0.25rem 0.625rem 0.25rem;
				@include font-size(12px);
			}
			&.centered {
				td {
					text-align: center;
					@include breakpoint (small only) {
						text-align: left;
					}
				}
			}
		}
	}
}
