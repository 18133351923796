/*
|--------------------------------------------------------------------------
| Access Login
|--------------------------------------------------------------------------
*/
.section {
	margin: 0;
	position: relative;
	overflow: hidden;
}
.section-header {
	height: 100vh;
	background-color: gray;
	.login {
		position: relative;
	    z-index: 1;
	    margin-top: 10vh;
	    color: #FFFFFF;
	    text-align: center;
	    width: 100%;
	    .register-box {
	    	background: none repeat scroll 0 0 #FFFFFF;
		    border: 1px solid #DFDFDF;
		    border-radius: 5px;
		    color: #333;
		    padding: 30px 20px;
		    h2 {
		    	@include font-size(26px);
		    }
		    p {
		    	@include font-size(16px);
		    }
		    .form {
		    	input {
		    		box-shadow: none;
		    		font-family: inherit;
		    		border-radius: 3px;
		    	}
		    	label {
		    		font-weight: 300;
		    		text-align: left;
		    		color: #333;
		    	}
		    }
	    }
	}
}
.background {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	img {
		width: 100%;
		height: auto;
		position: relative;
	}
	.filter {
		position: absolute;
	    width: 100%;
	    height: 100%;
	    display: block;
	    content: "";
	    top: 0;
	    left: 0;
	    opacity: 1;
	    z-index: 4;
	    background: -moz-linear-gradient(-45deg, rgba(86,141,226,0.6) 0%, #1d806e 100%);
	    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba(86,141,226,0.6)), color-stop(100%, #1d806e));
	    background: -webkit-linear-gradient(-45deg, rgba(86,141,226,0.6) 0%, #1d806e 100%);
	    background: -o-linear-gradient(-45deg, rgba(86,141,226,0.6) 0%, #1d806e 100%);
	    background: -ms-linear-gradient(-45deg, rgba(86,141,226,0.6) 0%, #1d806e 100%);
	    background: linear-gradient(135deg, rgba(86,141,226,0.6) 0%, #1d806e 100%);
	    background-size: 120% 120%;
	}
}
