/*
|--------------------------------------------------------------------------
| Header
|--------------------------------------------------------------------------
*/
header {
	background: -moz-linear-gradient(-45deg, rgba(86,141,226,0.6) 0%, #1d806e 100%);
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba(86,141,226,0.6)), color-stop(100%, #1d806e));
    background: -webkit-linear-gradient(-45deg, rgba(86,141,226,0.6) 0%, #1d806e 100%);
    background: -o-linear-gradient(-45deg, rgba(86,141,226,0.6) 0%, #1d806e 100%);
    background: -ms-linear-gradient(-45deg, rgba(86,141,226,0.6) 0%, #1d806e 100%);
    background: linear-gradient(135deg, rgba(86,141,226,0.6) 0%, #1d806e 100%);
	color: white;
	border-bottom: 1px solid darken($dark-gray, 10%);
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}

.top-bar {
	background: inherit;
	padding: 0 2rem 0 0.5rem;
	ul {
		background: inherit;
		li {
			a {
				color: white;
				display: block;
				line-height: 40px;
				max-height: 40px;
				padding: 0;
			}
			ul {
				background: white;
				box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
				li {
					a {
						color: $primary-color;
						padding: 10px 0 10px 1rem;
						line-height: 1.6;
						border-bottom: 1px solid lighten($primary-color, 20%);
						&:hover {
							background: rgba(119, 119, 119, 0.15);
						}
					}
				}
			}
		}
	}
	.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
		border-color: white transparent transparent;
	}
}
.title-bar {
	background: rgba(255, 255, 255, 0.1);
}
.left-menu {
	border-right: 1px solid white;
	background: -moz-linear-gradient(-45deg, rgba(86,141,226,0.6) 0%, #1d806e 100%);
    background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba(86,141,226,0.6)), color-stop(100%, #1d806e));
    background: -webkit-linear-gradient(-45deg, rgba(86,141,226,0.6) 0%, #1d806e 100%);
    background: -o-linear-gradient(-45deg, rgba(86,141,226,0.6) 0%, #1d806e 100%);
    background: -ms-linear-gradient(-45deg, rgba(86,141,226,0.6) 0%, #1d806e 100%);
    background: linear-gradient(135deg, rgba(86,141,226,0.6) 0%, #1d806e 100%);
	padding: 0;
	box-shadow: 1px 0 1px rgba(0, 0, 0, 0.2);
	@include breakpoint(medium large) {
		padding: 2rem 10px;

	}
	a {
		color: white;
		display: block;
	}
	ul {
		margin-left: 0;
		li {
			border-right: 4px solid transparent;
			ul.nested {
				margin-left: 0;
				li {
					transition: all 0.5s;
					border-right: 4px solid transparent;
					border-bottom: 1px solid transparent;
					a {
						display: block;
						color: white;
						border-bottom: 1px solid transparent;
						&:hover {
							background: rgba(255, 255, 255, 0.1);
							border-bottom: 1px solid rgba(255, 255, 255, 0.5);
						}
						i.fa {
							vertical-align: baseline;
						}
					}
					&.activar,
					&:hover {
						border-color: $primary-color;
						background: rgba(255, 255, 255, 0.1);
						border-bottom: 1px solid rgba(255, 255, 255, 0.5);
					}
				}
			}

			&.activar {
				border-color: $primary-color;
			}
		}
	}
}
